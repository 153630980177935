<template>
    <div class="himCreateBox">
        <Search />
        <div class="communityContent">
            <div class="nav">
                <div @click="goUstand">
                    <div class="img">
                        <img src="../../assets/images/community/logo.png" alt="" />
                    </div>
                    <p>了解企胖胖</p>
                </div>
                <div v-for="item in nav.slice(0,4)" :key="item.id" @click="navClickFun(item)">
                    <div class="img">
                        <img style="width:50px;height:50px" :src="$util.host +item.thumbnail" alt="">
                    </div>
                    <p>{{ item .name}}</p>
                </div>
                <div v-if="nav.length > 4">
                    <el-dropdown placement="bottom" trigger="click" @command="indexChange">
                        <img style="margin:17px 0 4px ;width:50px;height:50px;display:block" src="../../assets/images/serve/gd.png" alt="">
                        <span class="el-dropdown-link">
                            更多
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="item" v-for="item in nav.slice(4,100)" :key="item.id">{{item.name}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <!-- <div class="noneBox" v-if="showClass">
                    <p v-for="item in 3">
                        类别{{item}}
                    </p>
                    <p>我的创作中心</p>
                </div> -->
            </div>
        </div>
        <div class="contentBox">
            <div class="title">
                <div class="fh" @click="toRetu"> <img src="../../assets/images/community/fh (11).png" alt=""><span>返回</span></div>
                <span>{{userInfo[0].accountKey}}的创作中心</span>
                <div style=" cursor: pointer;">
                    <font @click="goSend">【我要发帖】</font>
                    <div>
                        <font @click="goInvitation">我的</font>
                    </div>
                </div>
            </div>
            <div class="textList">
                <div class="list">
                    <div class="text">
                        <div class="maker">
                            <span class="name">TA的帖子</span>
                            <div>
                                <span>主贴数：<font>{{userInfo[0].articleNum}}</font></span>
                                <span>回贴数：<font>{{userInfo[0].evaluateNum}}</font></span>
                            </div>
                        </div>
                        <div v-for="item in postList" :key="item.id" class="community" @click="goDetail(item.id)">
                            <!-- ../../assets/images/community/banner1.jpg -->
                            <img :src="$util.host +item.cover" alt="" />
                            <div>
                                <p>{{item.name}}</p>
                                <p><span>{{item.createTime}}</span>
                                    <font>通用区</font>
                                </p>
                                <p v-html="item.content">
                                </p>
                                <p><span>评论 {{item.commentNum}}</span><span>回复 {{item.replyNum}}</span></p>
                            </div>
                        </div>
                        <p class="seeMore" v-if="postList.length>5" @click="nextPage1">查看更多</p>
                    </div>
                    <div class="text">
                        <div class="maker">
                            <span class="name">TA的收藏</span>
                            <div>
                                <span>收藏数:<font>{{collectList.length}}</font></span>
                            </div>
                        </div>
                        <div v-for="item in collectList" :key="item.id" class="community" @click="goDetail(item.id)">
                            <img :src="$util.host +item.cover" alt="" />
                            <div>
                                <p>{{item.name}}</p>
                                <p><span>{{item.createTime}}</span>
                                    <font>通用区</font>
                                </p>
                                <p v-html="item.content">

                                </p>
                                <p><span>评论 {{item.commentNum}}</span><span>回复 {{item.replyNum}}</span></p>
                            </div>
                        </div>
                        <p class="seeMore" v-if="collectList.length>5">查看更多</p>
                    </div>
                    <div class="text">
                        <div class="maker">
                            <span class="name">TA的点赞</span>
                            <div>
                                <!-- <span>主贴数：<font>101万</font></span> -->
                                <span>点赞数:<font>{{likeList.length}}</font></span>
                            </div>
                        </div>
                        <div v-for="item in likeList" :key="item.id" class="community" @click="goDetail(item.id)">
                            <img :src="$util.host +item.cover" alt="" />
                            <div>
                                <p>{{item.name}}</p>
                                <p><span>{{item.createTime}}</span>
                                    <font>通用区</font>
                                </p>
                                <p v-html="item.content">

                                </p>
                                <p><span>评论 {{item.commentNum}}</span><span>回复 {{item.replyNum}}</span></p>
                            </div>
                        </div>
                        <p class="seeMore" v-if="likeList.length>5">查看更多</p>
                    </div>

                </div>
                <div class="user">
                    <div class="userInfo">
                        <img :src="$util.host +userInfo[0].headPic" alt="" />
                        <p class="name">{{userInfo[0].accountKey}}</p>
                        <div class="data">
                            <span>关注 <font>{{userInfo[0].fabulousNum}}</font></span>
                            <span>粉丝 <font>{{userInfo[0].fenSiNum}}</font></span>
                        </div>
                        <div class="btn">
                            <div v-if="!info.includes(userInfo[0].id)" @click="focusOn(userInfo[0].id)">关注TA</div>
                            <div v-else @click="focusOn(userInfo[0].id)">已关注</div>
                            <div @click="toDemand(userInfo[0].id)">TA的需求</div>
                        </div>
                        <div class="text">
                            {{userInfo[0].quotations}}
                        </div>
                    </div>
                    <div class="userData">
                        <p>TA的基本信息</p>
                        <p>
                            <img src="../../assets/images/community/gryh.png" /><span v-text="userInfo[0].accountType == 1?'个人用户':'企业用户'"></span>
                        </p>
                        <p><img src="../../assets/images/community/dw.png" />{{allPro[userInfo[0].city]}}</p>
                        <p><img src="../../assets/images/community/dshy.png" />{{userInfo[0].industry}}</p>
                    </div>
                    <!-- <div class="userFollow">
                        <div class="followTitle">
                            <span>TA关注的人</span>
                            <span><img src="../../assets/images/community/hyp.png" />换一批</span>
                        </div>
                        <div class="userList">
                            <div v-for="item in 3">
                                <span><img src="../../assets/images/community/mrtx.png" />bbb</span>
                                <span>+关注</span>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import Search from "../../components/forumSearch.vue";
import Footer from "../../components/footer.vue";
export default {
    components: { Search, Footer },
    name: "QppIndex",
    data() {
        return {
            activeName: "默认",
            active: 0,
            currentPage: 5,
            showClass: false,
            tab: ["默认", "排行", "精品"],
            nav: [

            ],
            industryList: [
                { id: 'A', title: '农、林、牧、渔业' },
                { id: 'B', title: '采矿业,' },
                { id: 'C', title: '制造业' },
                { id: 'D', title: '电力、热力、燃气及水生产和供应业' },
                { id: 'E', title: '建筑业' },
                { id: 'F', title: '批发和零售业' },
                { id: 'G', title: '交通运输、仓储和邮政业' },
                { id: 'H', title: '住宿和餐饮业' },
                { id: 'I', title: '信息传输、软件和信息技术服务业' },
                { id: 'J', title: '金融业' },
                { id: 'K', title: '房地产业' },
                { id: 'L', title: '租赁和商务服务业' },
                { id: 'M', title: '科学研究和技术服务业' },
                { id: 'N', title: '水利、环境和公共设施管理业' },
                { id: 'O', title: '居民服务、修理和其他服务业' },
                { id: 'P', title: '教育' },
                { id: 'Q', title: '卫生和社会工作' },
                { id: 'R', title: '文化、体育和娱乐业' },
                { id: 'S', title: '公共管理、社会保障和社会组织' },
                { id: 'T', title: '国际组织' },
            ], //行业
            id: this.$route.query.id,
            userInfo: {},
            allPro: {},
            postList: [],
            pageNo1: 1,
            pageSize1: 5,
            collectList: [],
            likeList: [],
            info: []
        };
    },
    mounted() {
        this.getUserInfo()
        this.getClass()
        this.getPro()
        this.getPost()
        this.getCollect()
        this.getLike()
        this.init()
    },
    methods: {
        //我要发帖
        goSend() {
            this.$router.push({ path: '/post' })
        },
        //我的帖子
        goInvitation() {
            this.$router.push({ path: '/invitation' })
        },
        toRetu() {
            this.$router.go(-1)
        },
        //TA的需求
        toDemand(val) {
            this.$router.push({ path: `/TAdemand?id=${val}` })
        },
        getClass() {
            this.$util.post('/shop/allClassify').then(res => {
                console.log(res);
                this.nav = res.data
            })
        },
        // 分类选择显示
        indexChange(item) {
            this.classifyId = item.id
            this.getTopping()
            this.getyFeatured()
            this.getListing()
            this.getDefult()
            this.getBalnner()

        },
        // 获取他人数据接口
        getUserInfo() {
            this.$util.post('/user/otherInfo', {
                uid: this.id
            }).then(res => {
                this.industryList.forEach(item => {
                    if (res.data[0].industry == item.id) {
                        res.data[0].industry = item.title
                    }
                })
                this.userInfo = res.data
            })
        },
        getPro() {
            this.$util.post('/address/allMap').then(res => {
                console.log(res);
                this.allPro = res.data.all

            })
        },
        // 帖子列表
        getPost() {
            this.$util.post('/forum/target/self', {
                uid: this.id,
                pageNo: this.pageNo1,
                pageSize: this.pageSize1
            }).then(res => {
                console.log(res);
                this.postList = this.postList.concat(res.data)
            })
        },
        // 加载更多
        nextPage1() {

        },
        // 收藏
        getCollect() {
            this.$util.post('/forum/target/collection', {
                uid: this.id
            }).then(res => {
                console.log(res);
                this.collectList = res.data
            })
        },
        // 点赞
        getLike() {
            this.$util.post('/forum/target/fabulous', {
                uid: this.id
            }).then(res => {
                console.log(res);
                this.likeList = res.data

            })
        },
        navClickFun(item) {
            this.$router.push({ path: '/community' })
        },
        goUstand() {
            this.$router.push({ path: '/understand' })
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        },
        // 关注
        focusOn(id) {
            this.$util.post('/user/fabulous', {
                uid: id
            }).then(res => {
                console.log(res);
                // location.reload();
                this.init()
                if (res.code !== 200) {
                    this.$message.error(res.message)
                }
            })
        },
        //查看详情
        goDetail(id) {
            this.$router.push({ path: "communityDetail", query: { id } });
        },
        //获取本人的信息
        init() {
            //"57587276,69032497"
            this.$util.post('/user/info').then(res => {
                this.info = res.data.fabulousUid.split(",")
                console.log(this.info);
            })
        }
    },
};
</script>
<style lang="scss" scoped>
.himCreateBox {
    .contentBox {
        background: #f6f6f6;
        width: 100%;
        padding-top: 25px;
        .user {
            width: 365px;
            .userFollow {
                padding: 30px;
                background: #ffffff;
                margin-top: 20px;
                .userList {
                    div {
                        padding-top: 30px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        span:nth-child(1) {
                            display: flex;
                            align-items: center;
                            img {
                                margin-right: 10px;
                            }
                            justify-content: left;
                        }
                        span:nth-child(2) {
                            color: #e40012;
                            text-align: center;
                            line-height: 40px;
                            font-size: 16px;
                            height: 40px;
                            width: 80px;
                            border-radius: 4px;
                            background: rgba(228, 0, 18, 0.1);
                        }
                    }
                }
                .followTitle {
                    border-bottom: 1px solid #eeeeee;
                    padding-bottom: 10px;
                    display: flex;
                    justify-content: space-between;
                    span:nth-child(1) {
                        font-size: 16px;
                    }
                    span:nth-child(2) {
                        display: flex;
                        align-items: center;
                        justify-content: right;
                        img {
                            width: 14px;
                            height: 11px;
                        }
                    }
                }
            }
            .userData {
                padding: 30px;
                background: #ffffff;
                margin-top: 20px;
                p:nth-child(1) {
                    border-bottom: 1px solid #eeeeee;
                    padding-bottom: 10px;
                    font-size: 16px;
                }
                p {
                    margin-bottom: 30px;
                    img {
                        margin-right: 5px;
                    }
                }
            }
            .userInfo {
                width: 365px;
                height: 380px;
                background: #ffffff;
                padding-top: 30px;
                .text {
                    padding: 0 30px;
                    font-size: 14px;
                    color: #666666;
                }
                .btn {
                    padding: 0 67px;
                    display: flex;
                    justify-content: space-between;
                    padding-top: 28px;
                    padding-bottom: 20px;
                    div {
                        width: 94px;
                        cursor: pointer;
                        height: 44px;
                        color: #e40012;
                        text-align: center;
                        line-height: 44px;
                        font-size: 16px;
                        border-radius: 4px;
                        background: rgba(228, 0, 18, 0.1);
                    }
                }
                .data {
                    padding: 0 77px;
                    display: flex;
                    padding-top: 30px;
                    font-size: 16px;
                    font {
                        color: #e40012;
                    }
                    justify-content: space-between;
                }
                img {
                    width: 120px;
                    height: 120px;
                    border-radius: 50%;
                    margin: auto;
                    display: block;

                    margin-bottom: 8px;
                }
                .name {
                    text-align: center;
                    font-size: 18px;
                }
            }
        }
        .textList {
            display: flex;
            justify-content: space-between;
            width: 1200px;
            margin: auto;
            margin-top: 10px;
            .list {
                width: 817px;
                .text {
                    width: 757px;
                    // height: 985px;
                    padding: 30px;
                    background: #ffffff;
                    position: relative;
                    margin-bottom: 80px;

                    .seeMore {
                        position: absolute;
                        bottom: -40px;
                        left: 380px;
                        cursor: pointer;
                        color: #999999;
                    }
                    .community {
                        cursor: pointer;
                        margin-top: 30px;
                        display: flex;
                        justify-content: space-between;

                        img {
                            width: 198px;
                            height: 198px;
                            margin-right: 20px;
                        }
                        div {
                            flex: 1;
                            // max-height: 198px;
                            overflow: hidden;
                            p:nth-child(1) {
                                color: #222222;
                                font-size: 22px;
                                font-weight: bold;
                                margin-bottom: 12px;
                            }
                            p:nth-child(2) {
                                padding-bottom: 10px;
                                span {
                                    color: #666666;
                                    font-size: 14px;
                                }
                                font {
                                    color: #2ea7e0;
                                    font-size: 14px;
                                    margin-left: 30px;
                                }
                            }
                            p:nth-child(3) {
                                -webkit-line-clamp: 1;
                                width: 540px;
                                overflow: hidden; /*溢出的部分隐藏*/
                                white-space: nowrap; /*文本不换行*/
                                text-overflow: ellipsis; /*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
                                height: 84px;
                                font-size: 16px;
                                color: #666666;
                                margin-bottom: 34px;
                                overflow: hidden;
                                /deep/img {
                                    width: 60px;
                                    height: 60px;
                                    display: none;
                                }
                                /deep/ p {
                                    overflow: hidden; /*溢出的部分隐藏*/
                                    white-space: nowrap; /*文本不换行*/
                                    text-overflow: ellipsis; /*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
                                }
                                /deep/ h1 {
                                    overflow: hidden; /*溢出的部分隐藏*/
                                    white-space: nowrap; /*文本不换行*/
                                    text-overflow: ellipsis; /*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
                                }
                            }
                            p:nth-child(4) {
                                display: flex;
                                justify-content: right;
                                color: #666666;
                                font-size: 16px;
                                span {
                                    margin-left: 30px;
                                }
                            }
                        }
                    }
                    .maker {
                        display: flex;
                        justify-content: space-between;
                        border-bottom: 1px solid #eeeeee;
                        padding-bottom: 20px;
                        .name {
                            font-size: 18px;
                        }
                        div {
                            display: flex;
                            justify-content: right;
                            span {
                                margin-left: 50px;
                                font-size: 16px;
                                font {
                                    color: #999999;
                                }
                            }
                        }
                    }
                }
            }
        }
        .fh {
            cursor: pointer;
            display: flex;
            align-items: center;
            span {
                font-size: 16px;
                margin-left: 0;
            }
            img {
                width: 22.15px;
                height: 22.15px;
            }
        }
    }
    .title {
        width: 1140px;
        height: 30px;
        background: #ffffff;
        margin: auto;
        display: flex;
        justify-content: space-between;
        padding: 15px 30px;
        align-items: center;
        span {
            color: #222222;
            font-size: 24px;
        }
        font {
            font-size: 16px;
            margin-left: 45px;
        }
        div {
            display: flex;
            justify-content: right;
        }
    }
    .noneBox {
        position: absolute;
        width: 98px;
        border: 1px solid #d1d1d1;
        right: -30px;
        top: 130px;
        background: #ffffff;
        z-index: 999 !important;
        padding: 20px 0;
        p {
            font-size: 14px;
            padding-bottom: 15px;
            cursor: pointer;
        }
        p:last-child {
            padding-bottom: 0;
        }
    }

    .communityContent {
        width: 1200px;
        margin: auto;

        .recommendBox {
            width: 1200px;
            height: 428px;
            .recommend {
                position: absolute;
                width: 100%;
                left: 0;
                height: 418px;
                background: #fff5f6;
            }
        }
        .bannerBox {
            margin-bottom: 50px;
            .bannerImg {
                width: 100%;
                height: 400px;
            }
        }
        .communityData {
            padding-bottom: 10px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 65px;
            width: 100%;
            align-items: center;
            border-bottom: 1px solid #f1f1f1;
            .title {
                color: #222222;
                font-size: 24px;
            }
            .data {
                display: flex;
                justify-content: right;
                div {
                    font-size: 16px;
                    margin-left: 50px;
                    font {
                        color: #999999;
                    }
                    span {
                        color: #222222;
                    }
                }
            }
        }

        .img {
            height: 50px;
            cursor: pointer;
            padding-bottom: 18px;
        }

        .nav {
            padding-bottom: 50px;
            padding-top: 40px;
            display: flex;
            justify-content: space-between;
            text-align: center;
            position: relative;
            p {
                cursor: pointer;
            }
        }
    }
}
</style>
